import { registerBlockType } from "@wordpress/blocks";
import {
	RichText,
	InspectorControls,
} from "@wordpress/block-editor";
import { Button, PanelBody } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useState } from "@wordpress/element";

import {
	useBlockProps,
	__experimentalGetElementClassName,
} from "@wordpress/block-editor";

import "./style.scss";


const BlockFAQEdit = ({ attributes, setAttributes }) => {
	const { items } = attributes;
	const [FAQItem, setFAQItem] = useState(false);

	const addItem = () => {
		const newItems = [...items, { text: "" }];
		setAttributes({ items: newItems });
	};

	const updateItem = (index, newData) => {
		console.log(newData);

		const newItems = [...items];
		newItems[index] = newData;
		setAttributes({ items: newItems });
	};

	const removeItem = (index) => {
		const newItems = [...items];
		newItems.splice(index, 1);
		setAttributes({ items: newItems });
	};

	const toogleText = (index) => {
		console.log('asd');
		if(index === FAQItem ){
			setFAQItem(false);
		}
		else{
			setFAQItem(index);
		}
	};

	return (
		<div className="byvet-faq " >
			<InspectorControls>
				{items.map((item, index) => (
					<div key={`faq_${index}`}>
						<PanelBody title={`Pytanie - ${index + 1}`}>
							<RichText
								style={{ marginTop: "30px" }}
								tagName="p"
								value={item.question}
								onChange={(question) => updateItem(index, { ...item, question })}
								placeholder={__("Pytanie", "moj-plugin")}
							/>

							<RichText
								style={{ marginTop: "30px" }}
								tagName="div"
								multiline="p"
								value={item.description}
								onChange={(description) =>
									updateItem(index, { ...item, description })
								}
								placeholder={__("odpowiedź", "moj-plugin")}
							/>
							<Button isDefault onClick={() => removeItem(index)}>
								{__("usuń", "moj-plugin")}
							</Button>
						</PanelBody>
					</div>
				))}
				<PanelBody>
					<Button isPrimary onClick={addItem}>
						{__("Dodaj", "moj-plugin")}
					</Button>
				</PanelBody>
			</InspectorControls>
			<div className="by__faq">
				{items.map((item, index) => (
					<div
						data-faq={`faq_${index}`}
						className={`by__faq__item ${index === FAQItem? "open" : ""}`}
						key={`faq_reder_${index}`}
					>

						<h4 
							className="by__faq__heading" 
							onClick={() => toogleText(index)}
						>
							{item.question}
							<button className="by__faq__icon">
								<svg width="39" height="24" viewBox="0 0 39 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M2 1.5L19.9487 21.5L37 1.5" stroke="#1F1C75" stroke-width="3" stroke-linecap="round"/>
								</svg>
							</button>
						</h4>
						<div className="by__faq__description">
							<div dangerouslySetInnerHTML={{__html: item.description}} />
						</div>
					</div>
				))}
			</div>
			
		</div>
	);
};

const BlockFAQSave = ({ attributes }) => {
	const { items } = attributes;

	return (
		<>
			<div className="by__faq">
				{items.map((item, index) => (
					<div
						data-faq={`faq_${index}`}
						className="by__faq__item"
						key={`faq_${index}`}
					>
							<h4 className="by__faq__heading">
								{item.question}
								<button className="by__faq__icon">
									<svg width="39" height="24" viewBox="0 0 39 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2 1.5L19.9487 21.5L37 1.5" stroke="#1F1C75" stroke-width="3" stroke-linecap="round"/>
									</svg>
								</button>
							</h4>
						
						<div className="by__faq__description">
							<div dangerouslySetInnerHTML={{__html: item.description}} />
						</div>
					</div>
				))}
			</div>
			
		</>
	);
};

(() => {
	const faqList = document.querySelectorAll(".by__faq__heading");
	console.log(faqList);
	if (faqList.length) {
		faqList.forEach((item) => {
			// console.log(item);
			item.addEventListener("click", function (e) {
				e.preventDefault();
				toogle(item);
			});
		});
	}
	function toogle(element) {
		const parent = element.closest('.by__faq__item');
		console.log(parent);
		const active = document.querySelector('.by__faq__item.open');
		if(parent.classList.contains('open')){
			parent.classList.remove('open');
		}
		else{
			if(active){
				active.classList.remove('open');
			}
			parent.classList.add('open');
		}
		
	}
})();



registerBlockType("moj-plugin/block-faq", {
	title: "ByVet Block FAQ",
	icon: "admin-appearance",
	category: "By vet",
	attributes: {
		items: {
			type: "array",
			default: [],
		},
	},
	edit: BlockFAQEdit,
	save: BlockFAQSave,
});
